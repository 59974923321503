<template>
  <div class="col">
    <!-- Start Scores -->
    <div class="my-4" style="margin-left: -15px; width: calc(100% + 30px)">
      <!--//?  Start Score Cards  -->
      <!-- //todo:  Start Medium & Small Screens  -->
      <div class="my-4 d-lg-none">
        <div class="col-sm-12 d-flex justify-content-center flex-column">
          <!-- //? Sequence  -->
          <div class="d-flex align-items-center">
            <p class="label sequence">
              {{ userScore.sequence != null ? userScore.sequence : 0 }}
            </p>
            <span style="font-size: 1.3rem; text-transform: capitalize">{{
              localization("Sequence")
            }}</span>
          </div>
          <!-- //? precision  -->
          <div class="d-flex align-items-center">
            <p class="label precision">
              {{ userScore.precision != null ? userScore.precision : 0 }}
            </p>
            <span style="font-size: 1.3rem; text-transform: capitalize">{{
              localization("precision")
            }}</span>
          </div>
          <!-- //? Technical Reasoning  -->
          <div class="d-flex align-items-center">
            <p class="label technical_reasoning">
              {{
                userScore.technical_reasoning != null
                  ? userScore.technical_reasoning
                  : 0
              }}
            </p>
            <span style="font-size: 1.3rem">{{
              localization("Technical Reasoning")
            }}</span>
          </div>
          <!-- //? Confluence  -->
          <div class="d-flex align-items-center">
            <p class="label confluence">
              {{ userScore.confluence != null ? userScore.confluence : 0 }}
            </p>
            <span style="font-size: 1.3rem; text-transform: capitalize">{{
              localization("Confluence")
            }}</span>
          </div>
        </div>
      </div>
      <!-- //todo: End Large Screens  -->

      <div class="my-4 d-none d-lg-block">
        <div class="col-sm-12 px-0 px-lg-3 d-flex align-items-center">
          <!-- Sequence  -->
          <card
            :height="`${userScore.sequence * 2.5 + 120}px`"
            style="
                background-color: #39c;
                border-radius: 0;
                border: 1px solid #ccc;
                width: 100%;
                max-height: 230px;
                min-height: 120px;
              "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->

              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ localization("Sequence") }}
              </p>
              <div
                class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("Sequence shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Small & medium Screens  -->

                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.sequence }}
                </p>
              </div>
            </div>
          </card>
          <!-- Precision   -->
          <card
            :height="`${userScore.precision * 2.5 + 120}px`"
            style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #3c3;
                max-height: 230px;
                min-height: 120px;
              "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->

              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ localization("precision") }}
              </p>

              <div
                class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("precision shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.precision }}
                </p>
              </div>
            </div>
          </card>
          <!-- Technical Reasoning  -->
          <card
            :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
            style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 1.6rem"
              >
                {{ localization("Technical Reasoning") }}
              </p>
              <div
                class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                    flex-column flex-md-row
                  "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("Technical Reasoning shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize:
                      (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.technical_reasoning }}
                </p>
              </div>
            </div>
          </card>
          <!-- Confluence  -->
          <card
            :height="`${userScore.confluence * 2.5 + 120}px`"
            style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #c36;
                max-height: 230px;
                min-height: 120px;
              "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ localization("Confluence") }}
              </p>

              <div
                class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("Confluence shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.confluence }}
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- End Scores -->

    <!-- Start Cms -->
    <card>
      <div class="new_wrapper_of_use_patterns">
        <div class="mb-3" v-if="careerMatchBoxes[0]">
          <div
            class="cms_strategy_wrapper"
            v-html="careerMatchBoxes[0].body"
          ></div>
        </div>
      </div>
    </card>
    <!-- End Cms -->

    <!-- Start Career Match Statements Questions-->
    <card>
      <div class="under_words">
        <div class="icons_wrapping_edit_print">
          <el-tooltip
            class=" mb-2 mr-2 ml-2 edit-btn print-btn main_tool_tip"
            content="Print Career Questions"
            placement="top-start"
          >
            <el-button
              @click="printPage"
              type="success"
              class="mb-2 mr-2 ml-2 edit-btn "
              ><i class="fa fa-print"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div>
        <!-- Start Collapse  -->
        <el-collapse multiple v-model="activeName">
          <div
            v-for="(statement, i) in careerMatchStatementsQuestions"
            :key="i"
          >
            <el-collapse-item
              :name="i"
              :class="{ reverse_while_arabic: isRtl() }"
            >
              <template #title>
                <div :class="{ 'w-100 text-right': isRtl() }">{{ i }}</div>
              </template>
              <ul
                class="main_statements_container list-unstyled"
                v-for="(item, i) in statement"
                :key="i"
              >
                <li class="every_li_wrapper">
                  <h4
                    class="every_pattern_key"
                    :style="`color: ${getProgressColor(i)}`"
                    :class="{ 'd-flex flex-row-reverse': isRtl() }"
                  >
                    <i
                      :class="`fa fa-star mr-1 ${i}`"
                      :style="`color: ${getProgressColor(i)}`"
                    ></i>
                    <span :class="{ 'mr-1': isRtl() }">{{ i }}</span>
                  </h4>
                  <ul class="second_list" :class="{ second_with_rtl: isRtl() }">
                    <li class="every_item">
                      <div v-for="(pat, i) in item" :key="i">
                        <div class="for_avoid_etc_words">{{ i }}</div>
                        <div v-if="pat">
                          <div v-for="state in pat" :key="state.id">
                            <el-checkbox-button
                              v-model="selectedItem"
                              :label="state.id"
                              @change="selectedStatements(state.id)"
                              :checked="
                                selectedItem.length > 0 &&
                                  selectedItem.includes(state.id)
                              "
                              :class="{
                                'when_checked_label  is_all_checked':
                                  selectedItem.length > 0 &&
                                  selectedItem.includes(state.id)
                              }"
                            >
                              <span
                                class="span_in_check_statement"
                                :class="{
                                  with_true:
                                    selectedItem.length > 0 &&
                                    selectedItem.includes(state.id)
                                }"
                              >
                                <i class="fa fa-check checker"></i>
                              </span>

                              <span class="wrap_while_exist">{{
                                state.statement
                              }}</span>
                            </el-checkbox-button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </div>
        </el-collapse>
        <!-- End Collapse  -->
      </div>
      <!-- Next And Back Buttons -->
      <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <!-- <router-link class="back_link" to="/career-match">
          {{ localization("next") }}
          <i class="fa fa-arrow-right back_icon mr-1"></i>
        </router-link> -->
        <button class="back_link" @click="goToCareerPage">
          {{ localization("next") }}
          <i class="fa fa-arrow-right back_icon mr-1"></i>
        </button>
      </div>
    </card>
    <!-- End Career Match Statements Questions -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      // for collapse
      activeName: "all",
      selectedItem: [],
      authUserData: {},
      selectedItemLength: false,
      careerMatchBoxes: null
    };
  },
  computed: {
    careerMatchStatementsQuestions() {
      return this.$store.getters["user/getCareerMatchStatementsQuestions"];
    },
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    how_to_use_your_patterns() {
      return this.$store.getters["user/get_how_to_use_your_patterns"];
    }
  },
  created() {
    this.handlerRefresh();
  },
  mounted() {
    this.Refresh();
    //
    this.$store.dispatch("user/handlerUserCareerMatchPageOne").then(res => {
      this.careerMatchBoxes = res.career_match_statement;
    });
  },

  methods: {
    Refresh(query) {
      this.$store
        .dispatch("user/handlerCareerMatchStatementsQuestions", {
          query: query
        })
        .then(res => {
          let obj = res.my_statements;
          // for (let key1 in obj) {
          //   for (let key2 in obj[key1]) {
          //     if (Array.isArray(obj[key1][key2])) {
          //       console.log(obj[key1][key2]);
          //     }
          //   }
          // }
        });
      this.$store.dispatch("user/handlerHowToUseYourPatterns");
      // Loop through the main object
      // for (let key1 in obj) {
      //   if (typeof obj[key1] === 'object') {
      //     // Loop through the nested objects
      //     for (let key2 in obj[key1]) {
      //       if (Array.isArray(obj[key1][key2])) {
      //         // Loop through the arrays
      //         obj[key1][key2].forEach(element => {
      //           console.log(element);
      //         });
      //       }
      //     }
      //   }
      // }
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    selectedStatements(stateId) {
      console.log(stateId);
      this.$store
        .dispatch("attributes/updateCareerMatchStatements", {
          id: this.auth().id,
          query: { my_career_statements: this.selectedItem }
        })
        .then(() => {
          this.Refresh();
          this.handlerRefresh();
        });
    },
    handlerRefresh() {
      this.$store.dispatch("user/user", this.auth().id).then(() => {
        this.authUserData = this.$store.state.user.user;

        // my_career_statements
        let everyUserStatements = this.$store.state.user.user
          .my_career_statements;
        if (everyUserStatements != null) {
          this.selectedItem = JSON.parse(
            this.$store.state.user.user.my_career_statements
          );
          this.selectedItemLength = this.selectedItem.length;
        }
      });
    },
    goToCareerPage() {
      if (this.selectedItemLength <= 11) {
        Swal.fire({
          icon: "warning",
          text: `Please answer the questions`,
          confirmButtonText: `${this.localization("Ok")}`,
          type: "success",
          confirmButtonClass: "btn btn-success ",
          buttonsStyling: false
        });
      } else {
        this.$router.push("/career-match");
      }
    },
    printPage() {
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.team_routes_wrapper {
  display: flex;
  gap: 10px;
  justify-content: end;
  flex-wrap: wrap;
  margin-top: 20px;
  .back_link {
    color: #777;
    background-color: #eee;
    padding: 8px 10px;
    display: block;
    width: fit-content;
    margin: 10px 0;
    border-radius: 8px;
    transition: 0.3s;
    border: none;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// start collapse style
.main_statements_container {
  padding-top: 15px;

  .every_pattern_key {
    margin: 0 0 15px 0;
  }
  .second_list {
    list-style: none;
    &.second_with_rtl {
      text-align: right;
      padding-right: 40px;
      padding-left: 0;
    }
  }
}
::v-deep .el-checkbox-button {
  .el-checkbox-button__original {
  }
  .el-checkbox-button__inner {
    border: none;
    background: #fff !important;
    display: flex;
    gap: 8px;
    .span_in_check_statement {
      border: 1px solid #ccc;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 4px;
      .checker {
        font-size: 9px;
        display: none;
      }
      &.with_true {
        background-color: #409eff;
        color: #fff;
        border: 1px solid #409eff;
        .checker {
          font-size: 9px;
          display: block;
        }
      }
    }
    &:hover {
      color: #000;
    }
  }
  &.is_all_checked {
    .el-checkbox-button__inner {
      // background: #77e311 !important;
      color: #409eff;
    }
  }
  .wrap_while_exist {
    color: #000;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.3;
    text-transform: initial;
  }
}
::v-deep .el-collapse-item__header {
  height: 70px;
}
.for_avoid_etc_words {
  color: #777;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 8px;
  position: relative;
}
// collapse style
/* mobile score progress design */
::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;

  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;

  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;

  background-color: #c36;
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
.letter {
  font-size: 40px;
}
.under_words {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  &.is_rtl {
    flex-direction: row-reverse;
  }
  .icons_wrapping_edit_print {
    display: flex;
    align-items: center;
    gap: 20px;
    @media print {
      display: none;
    }
    .print_task_button {
      // background-color: #fff;
      color: #fff;
      border: none;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease-in-out;
      background-color: #11bfe3;
      .icons_actions {
        font-size: 14px;
      }

      &.edit_task_button {
        background-color: #ffa534;
      }
      &.share {
        background-color: #3c3;
      }
    }
  }
}
</style>
